import { HttpError, User } from "@/models/auth";
import { FindInstruments } from "@/models/find-instruments";
import { RightColumnModule as StockShortsRightColumnModule } from "@/models/stock-shorts";
import { ModuleTemplate, TabTemplate } from "@/models/custom-views";
import { Universe, UserUniverses } from "@/models/universe";
import { isUndefined } from "lodash";
import { atom, selector } from "recoil";
import Color from "./Color";
import { v4 as uuidv4 } from "uuid";
import { Instrument } from "@/models/instruments";
import { Notification } from "@/models/notifications";
import { Plan, Subscription } from "@/models/subscription";

export const themeState = atom<"dark" | "light">({
    key: "themeState",
    default: undefined,
});

export const openMenuState = atom<0 | 1>({
    key: "openMenuState",
    default: undefined,
});

export const universeState = atom<Universe>({
    key: "universeState",
    default: undefined,
});

export const universesState = atom<UserUniverses>({
    key: "universesState",
    default: undefined,
});

export const regionState = atom<7 | 10>({
    key: "regionState",
    default: undefined,
});

export const ortexColorState = atom<string>({
    key: "ortexColor",
    default: "rgb(49, 171, 166)",
});

export const lighterOrtexColorState = atom<string>({
    key: "lighterOrtexColor",
    default: "rgb(49, 171, 166, 0.7)",
});

export const newsOptionsState = atom({
    key: "newsOptionsState",
    default: {},
});

export const positiveState = atom<string>({
    key: "positiveState",
    default: "rgb(49,171,166)",
});

export const positiveGradientState = selector<string>({
    key: "positiveGradientState",
    get: ({ get }) => {
        const positiveColor = get(positiveState);

        return "linear-gradient(270deg," + positiveColor + " 0%, transparent 100%)";
    },
});

export const negativeState = atom<string>({
    key: "negativeState",
    default: "rgb(239,68,56)",
});

export const negativeGradientState = selector<string>({
    key: "negativeGradientState",
    get: ({ get }) => {
        const negativeColor = get(negativeState);

        return "linear-gradient(90deg," + negativeColor + " 0%, transparent 100%)";
    },
});

export const neutralState = atom<string>({
    key: "neutralState",
    default: "rgb(171,133,109)",
});

export const greyState = atom<string>({
    key: "greyState",
    default: "rgb(128,128,128)",
});

export const blackState = atom<string>({
    key: "blackState",
    default: "rgb(0,0,0)",
});

export const whiteState = atom<string>({
    key: "whiteState",
    default: "rgb(255,255,255)",
});

export const blackMediumState = atom<string>({
    key: "blackMediumState",
    default: "rgb(21, 30, 35)",
});

export const darkDefaultState = atom<string>({
    key: "darkDefaultState",
    default: "rgb(15, 15, 15)",
});

export const darkSurfaceDefaultState = atom<string>({
    key: "darkSurfaceDefaultState",
    default: "rgb(21, 30, 35)",
});

export const whiteMediumState = atom<string>({
    key: "whiteMediumState",
    default: "rgb(247, 247, 247)",
});

export const iconColorState = atom<string>({
    key: "iconColorState",
    default: "rgb(117, 117, 117)",
});

export const dividerDarkState = atom<string>({
    key: "dividerDarkState",
    default: "rgb(62, 62, 62)",
});

export const dividerLightState = atom<string>({
    key: "dividerLightState",
    default: "rgb(240,240,240)",
});

export const borderDarkState = atom<string>({
    key: "borderDarkState",
    default: "rgb(62, 62, 62)",
});

export const borderLightState = atom<string>({
    key: "borderLightState",
    default: "rgb(255, 255, 255)",
});

export const dividerState = selector<string>({
    key: "dividerState",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? get(dividerDarkState) : get(dividerLightState);
    },
});

export const borderColorState = selector<string>({
    key: "borderState",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? get(borderDarkState) : get(borderLightState);
    },
});

export const greyLighterState = selector<string>({
    key: "greyLighterState",
    get: ({ get }) => new Color(get(greyState)).brighten(0.09).get() as string,
});

export const greyMediumState = selector<string>({
    key: "greyMediumState",
    get: ({ get }) => new Color(get(greyState)).brighten(-0.16).get() as string,
});

export const greyDarkerState = selector<string>({
    key: "greyDarkerState",
    get: ({ get }) => new Color(get(greyState)).brighten(-0.26).get() as string,
});

export const blackDarkerState = selector<string>({
    key: "blackDarkerState",
    get: ({ get }) => new Color(get(blackMediumState)).brighten(-0.03).get() as string,
});

export const blackLighterState = selector<string>({
    key: "blackLighterState",
    get: ({ get }) => {
        const blackMedium = new Color(get(blackMediumState)).brighten(0.07).get() as string;
        const rgb = blackMedium.match(/[0-9]+/g);
        rgb[0] = String(Math.max(0, Number(rgb[0]) - 8));
        rgb[2] = String(Math.min(255, Number(rgb[2]) + 8));
        return `rgb(${rgb.join(",")})`;
    },
});

export const whiteLighterState = selector<string>({
    key: "whiteLighterState",
    get: ({ get }) => new Color(get(whiteMediumState)).brighten(0.05).get() as string,
});

export const whiteDarkerState = selector<string>({
    key: "whiteDarkerState",
    get: ({ get }) => new Color(get(whiteMediumState)).brighten(-0.03).get() as string,
});

export const textColorState = selector<string>({
    key: "textColorState",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? (new Color(get(whiteState)).brighten(-0.1).get() as string) : get(blackState);
    },
});

export const backgroundColorState = selector<string>({
    key: "backgroundColor",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? get(blackMediumState) : get(whiteDarkerState);
    },
});

export const newbackgroundColorState = selector<string>({
    key: "newbackgroundColorState",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? get(darkDefaultState) : get(whiteState);
    },
});

export const darkerBackgroundColorState = selector<string>({
    key: "darkerBackgroundColor",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? get(darkDefaultState) : get(whiteDarkerState);
    },
});

export const lighterBackgroundColorState = selector<string>({
    key: "lighterBackgroundColor",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? get(blackLighterState) : get(whiteLighterState);
    },
});

export const tabLinksColorState = selector<string>({
    key: "tabLinksColorState",
    get: ({ get }) => {
        const theme = get(themeState);
        return theme === "dark" ? get(whiteState) : get(blackState);
    },
});

export const newsAutocompleteState = atom({
    key: "newsAutocompleteState",
    default: undefined,
});

export const filterChipsState = atom({
    key: "filterChipsState",
    default: [],
});

export const popoverContentState = atom<string>({
    key: "popoverContentState",
    default: "rgb(42, 58, 67)",
});

export const userDataState = atom<User>({
    key: "userDataState",
    default: undefined,
});

export const agreementsState = atom<{ code: string }[]>({
    key: "agreementsState",
    default: undefined,
});

export const moduleSettingsState = atom<{ [key: string]: any }>({
    key: "moduleSettingsState",
    default: {},
});

export const stockShortsExpandedState = atom<StockShortsRightColumnModule>({
    key: "stockShortsExpandedState",
    default: "short_shares",
});
export const searchSettingsState = atom<any[]>({
    key: "searchSettingsState",
    default: [],
});

export const tickerTapeState = atom<{ [identifier: string]: Instrument }>({
    key: "tickerTapeState",
    default: {},
});

export const hideTickertapeState = atom<boolean>({
    key: "hideTickertapeState",
    default: false,
});

export const customViewsState = atom<TabTemplate[]>({
    key: "customViewsState",
    default: [],
});

export const customViewsUnsavedState = atom<boolean>({
    key: "customViewsUnsavedState",
    default: false,
});

export const customViewsActiveTabState = atom<number>({
    key: "customViewsActiveTabState",
    default: null,
});

export const customViewsCurrentViewDataState = atom<TabTemplate[]>({
    key: "customViewsCurrentViewDataState",
    default: [],
});

export const customViewsEditModeState = atom<boolean>({
    key: "customViewsEditModeState",
    default: false,
});

export const customViewsSpeedDialState = atom<boolean>({
    key: "customViewsSpeedDialState",
    default: false,
});

export const customViewsCompactTypeState = atom<"horizontal" | "vertical" | null>({
    key: "customViewsCompactTypeState",
    default: "horizontal",
});

export const customViewsPreventCollisionState = atom<boolean>({
    key: "customViewsPreventCollisionState",
    default: false,
});

export const customViewsDraggableItemState = atom<ModuleTemplate>({
    key: "customViewsDraggableItemState",
    default: undefined,
});

export const customViewsComponentsState = atom<ModuleTemplate[]>({
    key: "customViewsComponentsState",
    default: [],
});

export const userState = selector<User>({
    key: "userState",
    get: ({ get }) => ({
        ...get(userDataState),
        settings: {
            theme: get(themeState),
            region: get(regionState),
            universe: get(universeState),
            openMenu: get(openMenuState),
            positive: get(positiveState),
            negative: get(negativeState),
            neutral: get(neutralState),
            grey: get(greyState),
            black: get(blackState),
            white: get(whiteState),
            blackMedium: get(blackMediumState),
            whiteMedium: get(whiteMediumState),
            module_settings: get(moduleSettingsState),
            agreements: get(agreementsState),
            stock_shorts_expanded: get(stockShortsExpandedState),
            search_settings: get(searchSettingsState),
        },
    }),
    set: ({ set }, value: User) => {
        const user = { ...value };
        delete user.settings;
        set(userDataState, value ? user : null);
        if (value) {
            if (value.settings.theme) set(themeState, value.settings.theme);
            if (isFinite(value.settings.openMenu)) set(openMenuState, value.settings.openMenu);
            set(universeState, value.settings.universe || { id: 7, name: "US & Canada", US_stocks: true });
            set(regionState, isUndefined(value.settings.region) ? 7 : value.settings.region);
            set(positiveState, isUndefined(value.settings.positive) ? "rgb(49,171,166)" : value.settings.positive);
            set(negativeState, isUndefined(value.settings.negative) ? "rgb(239,68,56)" : value.settings.negative);
            set(neutralState, isUndefined(value.settings.neutral) ? "rgb(171,133,109)" : value.settings.neutral);
            set(greyState, isUndefined(value.settings.grey) ? "rgb(128,128,128)" : value.settings.grey);
            set(blackState, isUndefined(value.settings.black) ? "rgb(0,0,0)" : value.settings.black);
            set(whiteState, isUndefined(value.settings.white) ? "rgb(255,255,255)" : value.settings.white);
            set(blackMediumState, isUndefined(value.settings.blackMedium) ? "rgb(21, 30, 35)" : value.settings.blackMedium);
            set(whiteMediumState, isUndefined(value.settings.whiteMedium) ? "rgb(247, 247, 247)" : value.settings.whiteMedium);
            set(moduleSettingsState, isUndefined(value.settings.module_settings) ? {} : value.settings.module_settings);
            set(agreementsState, isUndefined(value.settings.agreements) ? [] : value.settings.agreements);
            set(stockShortsExpandedState, isUndefined(value.settings.stock_shorts_expanded) ? "short_shares" : value.settings.stock_shorts_expanded);
            set(searchSettingsState, isUndefined(value.settings.search_settings) ? [] : value.settings.search_settings);
        }
    },
});

export const clearFiltersState = atom({
    key: "clearFiltersState",
    default: false,
});

export const subscriptionOfferingsState = atom({
    key: "subscriptionOfferingsState",
    default: null,
});

export const subscriptionState = atom<Subscription>({
    key: "subscriptionState",
    default: null,
});

export const planState = atom<Plan>({
    key: "planState",
    default: null,
});

export const codeAppliedState = atom<string>({
    key: "codeAppliedState",
    default: "",
});

export const promoCodeDisabledState = atom<boolean>({
    key: "promoCodeDisabledState",
    default: true,
});

export const paymentDrawerOpenState = atom({
    key: "paymentDrawerOpenState",
    default: true,
});

export const paymentModalOpenState = atom({
    key: "paymentModalOpenState",
    default: false,
});

export const showAppSubscriberModalState = atom({
    key: "showAppSubscriberModalState",
    default: false,
});

export const notificationsListState = atom<Notification[]>({
    key: "notificationsListState",
    default: [],
});

export const stockTradingItemIdState = atom<number>({
    key: "stockTradingItemIdState",
    default: null,
});

export const showAgreementsModalState = atom({
    key: "showAgreementsModalState",
    default: false,
});

export const balanceDetailsState = atom<{ buyingPower: number; cash: number; cashWithdrawable: number; equity: number; longMarketValue: number }>({
    key: "balanceDetailsState",
    default: {
        buyingPower: null,
        cash: null,
        cashWithdrawable: null,
        equity: null,
        longMarketValue: null,
    },
});

export const tickerWSState = atom<string[]>({
    key: "tickerWSState",
    default: [],
});

export const wsListState = atom<string[]>({
    key: "wsListState",
    default: [],
});

export const alertsListState = atom<any[]>({
    key: "alertsListState",
    default: [],
});
