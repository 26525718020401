import { KeyboardEvent, KeyboardEventHandler, useEffect, useRef, useState } from "react";
import styles from "./InputFilter.module.scss";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Box, InputAdornment, OutlinedInput, Select, MenuItem, Typography, Divider, Chip, Card, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { newsAutocompleteState, dividerState, greyLighterState, themeState, backgroundColorState } from "@/lib/store";
import { findInstruments } from "@/controllers/find-instruments";
import { useDebounce } from "react-use";
import { DataGridHeaders } from "@/models/datagrid";
import Autocomplete from "../autocomplete/Autocomplete";

type Props = {
    fields: Array<any>;
    setParams?: (params: any) => void;
    values?: Array<any>;
    chips: Array<any>;
    setChips: React.Dispatch<React.SetStateAction<any[]>>;
    isNewsSearch?: boolean;
    stockColumns?: Array<DataGridHeaders>;
    initialParams?: any[];
    smallAutocomplete?: boolean;
};

let searchTimeout: NodeJS.Timeout;

const InputFilter = ({ fields, setParams, values, chips, setChips, isNewsSearch, stockColumns, initialParams, smallAutocomplete }: Props) => {
    const theme = useRecoilValue(themeState);
    const greyLighter = useRecoilValue(greyLighterState);
    const dividerColor = useRecoilValue(dividerState);
    const backgroundColor = useRecoilValue(backgroundColorState);

    const [searchValue, setSearchValue] = useState("");
    const [selectValue, setSelectValue] = useState(fields.find((field) => field.field !== "tradingitemId").headerName);
    const setNewsAutocomplete = useSetRecoilState(newsAutocompleteState);
    const newsAutocomplete = useRecoilValue(newsAutocompleteState);

    const [selectedField, setSelectedField] = useState(fields.find((field) => field.headerName === selectValue));

    const timeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        console.log(fields, stockColumns);
    }, []);

    const stockColumnDefinition = stockColumns?.[0]?.field;

    const onDelete = (chip) => {
        const filteredValue = stockColumnDefinition === "tradingitem_id" ? chip.tradingItemId : isNewsSearch ? chip.companyId ?? chip.value : chip.value;
        const newChips = chips.filter((c) => (stockColumnDefinition === "tradingitem_id" ? c.tradingItemId : isNewsSearch ? c.companyId ?? c.value : c.value) !== filteredValue);
        setChips(newChips);
        setParams &&
            setParams((prevParams) => {
                const newParams = prevParams.customFilterModel?.items.filter((item) => item.value !== filteredValue) || [];
                return {
                    ...prevParams,
                    customFilterModel: {
                        items: [...newParams],
                    },
                };
            });
    };

    const onClick = (instrument) => {
        const newChip = instrument
            ? {
                  value: isNewsSearch ? instrument.company_id : instrument.value,
                  headerName: selectValue,
                  stockName: instrument?.ticker,
                  tradingItemId: instrument.tradingitem_id,
                  companyId: instrument.company_id,
              }
            : { value: searchValue, headerName: selectValue };

        setChips((prevChips) => [...prevChips, newChip]);

        instrument && setNewsAutocomplete({ onClick, data: [] });

        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            const formattedValue = {
                columnField: instrument && !isNewsSearch && stockColumnDefinition ? stockColumnDefinition : selectedField.field,
                id: Date.now(),
                operatorValue: instrument ? "equals" : "contains",
                value: instrument ? (isNewsSearch ? instrument.company_id : stockColumnDefinition === "tradingitem_id" ? instrument.tradingitem_id : instrument.value) : searchValue,
            };

            setParams &&
                setParams((prevParams) => {
                    const oldParams = prevParams?.customFilterModel?.items || [];
                    return {
                        ...prevParams,
                        customFilterModel: {
                            items: [...oldParams, formattedValue],
                        },
                    };
                });
            setSearchValue("");
        }, 1000);
    };

    const onChangeSelect = ({ target: { value } }) => {
        setSelectValue(value);
        setSelectedField(fields.find((field) => field.headerName === value));
    };

    const setChipBgColor = (headerName: string) => {
        const colorMap = {
            source: "#faebd7",
            stock: "#8fbc8f",
        };
        return colorMap[headerName.toLowerCase()] || (theme !== "dark" ? dividerColor : "#e7e7e8");
    };

    const getInstruments = (event: KeyboardEvent<HTMLInputElement>) => {
        setNewsAutocomplete({ onClick, loading: true, data: [] });
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            findInstruments(event.target["value"], true).then((data) => {
                setNewsAutocomplete({ onClick, loading: false, data });
            });
        }, 500);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key !== "Enter") {
            return;
        }

        if (selectedField.format !== "company" && selectedField.format !== "stock_list") {
            event.preventDefault();
            event.stopPropagation();
            searchValue && onClick(null);
        }
    };

    const handleOnChange = (event) => {
        setSearchValue(event.target.value);
    };

    useDebounce(
        () => {
            if (selectedField.format === "company" || selectedField.format === "stock_list") {
                if (searchValue) {
                    getInstruments({ target: { value: searchValue } } as any);
                } else {
                    setNewsAutocomplete({ onClick, loading: false, data: [] });
                }
            }
        },
        500,
        [searchValue]
    );

    const handleInputBlur = () => {
        setTimeout(() => {
            setSearchValue("");
            setNewsAutocomplete({ onClick, loading: false, data: [] });
        }, 300);
    };

    return (
        <>
            {!smallAutocomplete && (
                <>
                    <Typography variant="subtitle1" sx={{ fontSize: "14px", fontWeight: "700", textAlign: "left", width: "100%" }}>
                        Filter by
                    </Typography>
                    <Divider flexItem sx={{ mb: 0.5, backgroundColor: dividerColor }} />
                </>
            )}
            <Box sx={{ width: "100%" }}>
                <Box className={styles.form} sx={{ display: "flex", flexDirection: "row !important", mt: !smallAutocomplete && 2 }}>
                    <OutlinedInput
                        autoCapitalize="none"
                        autoComplete="off"
                        className={styles.input}
                        id="outlined-adornment-amount"
                        size="small"
                        value={searchValue}
                        onChange={handleOnChange}
                        sx={{ width: smallAutocomplete && "100% !important", position: "relative" }}
                        endAdornment={
                            <div className={styles.adornment}>
                                <InputAdornment position="end" onClick={() => searchValue && onClick(null)}>
                                    <AddIcon htmlColor={greyLighter} />
                                </InputAdornment>
                            </div>
                        }
                        placeholder={smallAutocomplete ? "Select stocks..." : "Search by..."}
                        name="search"
                        onKeyDown={handleKeyDown}
                        onBlur={handleInputBlur}
                    />
                    {fields.length > 1 && (
                        <Select className={styles.select} onChange={onChangeSelect} size="small" value={selectValue} displayEmpty renderValue={(value) => (value === "" ? "Select an option" : value)}>
                            {fields
                                .filter((field) => field.headerName)
                                .map((field) => (
                                    <MenuItem value={field.headerName} sx={{ fontSize: "12px", textTransform: "capitalize" }} key={field.headerName}>
                                        {field.headerName}
                                    </MenuItem>
                                ))}
                        </Select>
                    )}
                </Box>

                <Box className={smallAutocomplete && styles.smallAutocomplete} sx={{ position: "absolute", width: "95%", zIndex: 10, backgroundColor: backgroundColor }}>
                    {newsAutocomplete?.loading && (
                        <Card style={{ width: "100%", height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress />
                        </Card>
                    )}
                    {newsAutocomplete?.data?.length > 0 && (
                        <Card>
                            <Autocomplete instruments={newsAutocomplete?.data} onClick={newsAutocomplete?.onClick} smallAutocomplete={smallAutocomplete} />
                        </Card>
                    )}
                </Box>
                <Box className={styles.chips}>
                    {chips?.map((chip) => (
                        <Chip
                            className={styles.chip}
                            label={chip.stockName || chip.value}
                            onDelete={() => onDelete(chip)}
                            key={`${chip.headerName} ${chip.value}`}
                            size="small"
                            sx={{
                                backgroundColor: setChipBgColor(chip.headerName),
                                color: theme === "dark" && "#000",
                                "& .MuiChip-deleteIcon": {
                                    color: theme === "dark" && "grey",
                                    ":hover": {
                                        color: "#ef4438ed",
                                    },
                                },
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </>
    );
};

export default InputFilter;
