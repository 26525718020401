import dayjs, { Dayjs } from "dayjs";
import { isFinite, isUndefined, uniq } from "lodash";
import { categoryTypes, colors, usExchanges } from "./vars";
import { isDeepEqual } from "@mui/x-data-grid/internals";
import { Capacitor } from "@capacitor/core";
import { useCallback } from "react";

export const classNames = (...classes: string[]) => classes.join(" ");

const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
    { value: 1e9, symbol: "b" },
    { value: 1e12, symbol: "t" },
    { value: 1e15, symbol: "q" },
];

export const getNewEventsTypes = (eventsNames: string[]) => {
    const rawEvents = uniq(Object.keys(categoryTypes).concat(eventsNames)).map((type, i) => ({ name: type, color: colors[i % colors.length] }));
    const newEvents = rawEvents.slice(Object.keys(categoryTypes).length, rawEvents.length - 1);
    return JSON.stringify(
        newEvents.reduce((prev, curr) => {
            prev[curr.name] = [curr.color];
            return prev;
        }, {})
    );
};

export const repeatListValues = (list: any[]) => {
    const arr = [];
    list.forEach((value) => arr.push(value, value));
    return arr;
};

export const permutation = (...lists: any[][]): any[][] => {
    const r = [];
    const max = lists.length - 1;
    const helper = (list: any[], i: number) => {
        for (var j = 0, l = lists[i].length; j < l; j++) {
            var a = list.slice(0);
            a.push(lists[i][j]);
            if (i === max) r.push(a);
            else helper(a, i + 1);
        }
    };
    helper([], 0);
    return r;
};

export const toTwoDecimals = (num: number) => Number(num?.toFixed(2));

export const nFormatter = (num: number, dec: number | "auto" = 0) => {
    if (!isFinite(num)) return "N/A";
    let i = 0;
    const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
    for (i; i < si.length - 1; i++) {
        if (Math.abs(num) < si[i + 1]?.value) break;
    }
    if (dec === "auto") {
        let decimals: number = 0;
        switch (Math.floor(num / si[i].value).toString().length) {
            case 1:
                decimals = 2;
                break;
            case 2:
                decimals = 1;
                break;
            case 3:
                decimals = 0;
                break;
        }
        return si[i] ? (num / si[i].value).toFixed(decimals).replace(regex, "$1") + si[i].symbol : "N/A";
    } else {
        return si[i] ? (num / si[i].value).toFixed(dec).replace(regex, "$1") + si[i].symbol : "N/A";
    }
};

export const sFormatter = (num: number, digits: number) => {
    if (!isFinite(num)) return "N/A";
    let i = 0;
    for (i; i < si.length; i++) {
        if (num < si[i + 1].value) break;
    }
    return (
        String(num / si[i].value)
            .substr(0, digits + 1)
            .replace(/\.$/, "") + si[i].symbol
    );
};

export const mFormatter = (num: number) => {
    if (!isFinite(num)) return "N/A";
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const thousandsSeparator = (num: number) => {
    const parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
};

const isFirstWeekday = (date, today) => {
    return today.day() === 1 && date.day() === 5 && today.diff(date, "days") === 3;
};

//possible formats: "D MMM YYYY", "D MMM YYYY [at] HH:mm","Do MMM [at] HH:mm", "[on] ddd, Do MMM [at] HH:mm", "D MMM", "dddd",
function formatTime(dateTime: Dayjs, dateFormat: string): string {
    return dateFormat.includes("HH:mm") ? dateTime.format("HH:mm") : "";
}

export const dateParser = (date: Dayjs, format: string, timezone?: string, last_close?: boolean) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dateUTC = date.utc();
    const dateTime = timezone ? (timezone === "UTC" ? dateUTC : dateUTC.tz(timezone)) : dateUTC.tz(userTimezone);

    const today = timezone ? (timezone === "UTC" ? dayjs().startOf("day") : dayjs().tz(timezone).startOf("day")) : dayjs().tz(userTimezone).startOf("day");
    const yesterday = today.subtract(1, "day");
    const tomorrow = today.add(1, "day");

    const dateFormat = dateTime.year() === today.year() ? format.replace("YYYY", "") : format;

    if (dateTime.isSame(today, "day")) {
        return formatTime(dateTime, dateFormat) || "Today";
    }

    if (dateTime.isSame(tomorrow, "day")) {
        return `Tomorrow${formatTime(dateTime, dateFormat) ? " at " + formatTime(dateTime, dateFormat) : ""}`;
    }

    if (dateTime.isSame(yesterday, "day")) {
        return last_close ? "Last Close" : `Yesterday${formatTime(dateTime, dateFormat) ? " at " + formatTime(dateTime, dateFormat) : ""}`;
    }

    if (isFirstWeekday(dateTime, today)) {
        return last_close ? "Last Close" : dateTime.format(dateFormat);
    }

    return dateTime.format(dateFormat);
};

export const statitics = (array: number[]): [number, number, number, number, number] => {
    const sorted = array.sort((a, b) => a - b);
    const min = sorted[0];
    const max = sorted[sorted.length - 1];
    const i1 = Math.trunc((sorted.length % 2 === 0 ? sorted.length : sorted.length + 1) / 4 - 1);
    const i2 = Math.trunc((sorted.length % 2 === 0 ? sorted.length : sorted.length + 1) / 2 - 1);
    const i3 = Math.trunc((3 * (sorted.length % 2 === 0 ? sorted.length : sorted.length + 1)) / 4 - 1);
    const q1 = sorted.length % 2 === 0 ? (sorted[i1] + sorted[i1 + 1]) / 2 : sorted[i1];
    const q2 = sorted.length % 2 === 0 ? (sorted[i2] + sorted[i2 + 1]) / 2 : sorted[i2];
    const q3 = sorted.length % 2 === 0 ? (sorted[i3] + sorted[i3 + 1]) / 2 : sorted[i3];
    return [min, q1, q2, q3, max];
};

export const convertBase64 = (data: string) => {
    const binaryString = atob(data);
    const rawBytes = new Uint8Array(binaryString.length);
    const bytes = rawBytes.map((_, i) => binaryString.charCodeAt(i));
    return bytes.buffer;
};

export const priceDiffPercentage = (firstPrice: number, secondPrice: number) => ((secondPrice - firstPrice) / Math.abs(firstPrice)) * 100;

export const isMobileSafari = () => {
    if (typeof window !== "undefined") {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        return iOS && webkit && !ua.match(/CriOS/i);
    }
    return false;
};

export const getMainSession = (sessions: string[]) => {
    if (sessions.length === 1) return sessions[0];
    return sessions.reduce<{ session: string; diff: number }>(
        (prev, curr) => {
            const [open, close] = curr.split("-");
            const diff = dayjs(close, "HHmm").diff(dayjs(open, "HHmm"), "minutes");
            return diff < prev.diff ? prev : { session: curr, diff };
        },
        { session: undefined, diff: undefined }
    ).session;
};

export const getCurrentSessionInterval = (sessions: string[], timezone: string): -1 | 0 | 1 => {
    if (!sessions.length) return null;

    const session = getMainSession(sessions);

    const [openRaw, closeRaw] = session.split("-");
    const open = dayjs(openRaw, "HHmm");
    const close = dayjs(closeRaw, "HHmm");

    let currentTimeRaw = "";

    try {
        currentTimeRaw = dayjs().tz(timezone).format("HHmm");
    } catch (err) {
        currentTimeRaw = dayjs().format("HHmm");
    }

    const currentTime = dayjs(currentTimeRaw, "HHmm");
    const startOfDay = dayjs().startOf("day");
    const endOfDay = dayjs().endOf("day");

    if (currentTime.isBetween(startOfDay, open, "minutes", "[)")) return -1;
    else if (currentTime.isBetween(open, close, "minutes", "[)")) return 0;
    else if (currentTime.isBetween(close, endOfDay, "minutes", "[)")) return 1;
    else return null;
};

/** Returns true if the number is 0 (integer) or if the first 2 decimals are zero on floats */
export const isCloseToZero = (value: number) => {
    if (Number.isInteger(value) && value !== 0) return false;
    return Number(value.toFixed(2).split(".")[1]) === 0;
};

export const toggleElement = (array: any[], element: any) => {
    const index = array.indexOf(element);
    if (index !== -1) return array.filter((_, i) => i !== index);
    return [...array, element];
};

export const detectDeviceType = () => {
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobileScreen = typeof window !== "undefined" && window.matchMedia("(max-width: 767px)").matches;
    const userAgent = typeof window !== "undefined" && navigator.userAgent;

    if (userAgent && isMobileScreen) {
        const mobileType = mobileRegex.test(userAgent);

        if (userAgent === "Ortex App") return "Ortex App";
        else if (mobileType) return "Desktop";
    }
    return "Desktop";
};

export const detectBot = () => {
    const userAgent = typeof window !== "undefined" && navigator.userAgent;

    const botPattern = /bot|spider|crawler|crawl|scraper|inspect/i;

    return userAgent && botPattern.test(userAgent);
};

export const getLink = (row, link) => {
    const { exchangeSymbol, exchange, id, stock_id, stockId, ticker } = row;
    const normalizedLink = link.startsWith("/") ? link : `/${link}`;
    if (!(exchangeSymbol || exchange) || !ticker) return `/id/${stock_id || stockId || id}${normalizedLink}`;
    return `/s/${exchangeSymbol || exchange}/${ticker}${normalizedLink}`;
};

export const areEqual = (prevProps, nextProps) => isDeepEqual(prevProps.data, nextProps.data) && isDeepEqual(prevProps.id, nextProps.id);

export const isHex = (hex) => {
    return /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
};

export const hexToRgba = (hex, opacity) => {
    let c;

    c = hex.substring(1).split("");
    if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + opacity + ")";
};

export const rgbToRgba = (rgb, opacity) => {
    return rgb.replace(")", `,${opacity})`).replace("rgb", "rgba");
};

// Get the difference between the current time and the last update time for Ticker Carousel
export const getTimeDifference = (timestamp: number) => {
    const lastUpdate = dayjs(timestamp).format("HH-mm").split("-");
    const currentTime = dayjs().format("HH-mm").split("-");
    const timeDiff = [parseInt(currentTime[0]) - parseInt(lastUpdate[0])];
    timeDiff.push(parseInt(currentTime[1]) - parseInt(lastUpdate[1]));

    return timeDiff;
};

export const generateNonce = (length = 16) => {
    const array = new Uint8Array(length);
    if (typeof window !== "undefined") {
        window.crypto.getRandomValues(array);
        return Array.from(array)
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");
    }

    return "";
};

export const tryCatchWrapper = <T>(callback: () => T, data?: Record<string, any>) => {
    try {
        callback();
    } catch (error) {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            console.error("An error occurred: \n", error, "\n", { page: typeof window !== "undefined" && window.location.href, ...data });
        } else {
            console.error("An error occurred: \n", error);
        }
    }
};

export const detectTouchDeviceOnly = () => {
    const isBrowser = typeof window !== "undefined";
    const isOrtexApp = isBrowser && navigator.userAgent === "Ortex App";

    if (isOrtexApp) return true;

    const hasTouch = isBrowser && ("ontouchstart" in window || navigator.maxTouchPoints > 0 || (window.matchMedia && matchMedia("(pointer:coarse)").matches));
    const hasMouse = isBrowser && window.matchMedia && matchMedia("(pointer:fine)").matches;

    return hasTouch && !hasMouse;
};

export const formatDate = (date: Dayjs): string => {
    const currentYear = dayjs().year();
    const dateYear = date.year();

    if (currentYear === dateYear) {
        return date.format("DD MMM");
    } else {
        return date.format("DD MMM YY");
    }
};

export const isOrtexApp = () => {
    const userPlatform = Capacitor.getPlatform();
    return userPlatform === "android" || userPlatform === "ios";
};

export const sendWsMessage = (tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket) => {
    if (tickerStock !== undefined) {
        if (!tickerStock[0]) {
            if (ortexWebSocket.readyState === 1) {
                ortexWebSocket.send(JSON.stringify({ type: "subscribe", identifier: tickerStock[0] }));
            }
        } else {
            if (tickerStock[1].exchange) {
                if (usExchanges.includes(tickerStock[1].exchange)) {
                    if (obookWebSocket.readyState === 1) obookWebSocket.send(JSON.stringify({ type: "subscribe", identifier: tickerStock[1].ticker }));
                } else {
                    if (ortexWebSocket.readyState === 1) ortexWebSocket.send(JSON.stringify({ type: "subscribe", identifier: tickerStock[1].isin + tickerStock[1].currency }));
                }
            } else {
                if (multiWebSocket.readyState === 1) multiWebSocket.send(JSON.stringify({ type: "subscribe", ISIN: tickerStock[1].ISIN ? tickerStock[1].ISIN : tickerStock[0], currency: tickerStock[1].currency, depth: "false" }));
            }
        }
    }
};

export const unsuscribeFromWS = (tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket) => {
    if (!tickerStock[1].exchange) {
        multiWebSocket.readyState === 1 && multiWebSocket.send(JSON.stringify({ type: "unsubscribe", identifier: tickerStock[0] }));
    } else if (usExchanges.includes(tickerStock[1].exchange)) {
        obookWebSocket.readyState === 1 && obookWebSocket.send(JSON.stringify({ type: "unsubscribe", identifier: tickerStock[1].ticker }));
    } else {
        ortexWebSocket.readyState === 1 && ortexWebSocket.send(JSON.stringify({ type: "unsubscribe", identifier: `${tickerStock[1].isin}${tickerStock[1].currency}` }));
    }
};

export const camelToSnakeCase = (str: string) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
